import React from 'react';
import { Header, Footer, Companies, Arizaga, Offer } from "./containers";
import { Navbar } from "./components";

const Home = () => {
  return (
    <div>
        <Header />
        <Offer />
        <Companies />
        <Arizaga />
        <Footer />
    </div>
  )
}

export default Home