export const data = [
    {title: "Filtros estandar y bajo plano"},
    {title: "Resortes bajo plano"},
    {title: "Red de incendios"},
    {title: "Reductores"},
    {title: "Productos y equipamientos para laboratorios"},
    {title: "Acoplamientos"},
    {title: "Cadenas"},
    {title: "Materiales antiderrames"},
    {title: "Ejes cardanicos"},
    {title: "Válvulas"},
    {title: "Insumos para informática"},
    {title: "Bombas hidráulicas - neumáticas - mecánicas"},
    {title: "Seguridad industrial - Seguridad vial"},
    {title: "Repuestos para maquinas viales"},
    {title: "Materiales eléctricos industriales"},
    {title: "Conexiones, accesorios y caños"},
    {title: "O'RING, sellos mecánicos, estándar y bajo plano"},
    {title: "Cilindros bajo plano - hidráulicos - neumáticos"},
 ];
