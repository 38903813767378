import React from 'react';
import "./footer.css";
import phoneicon from "../../images/telefonoicon.png"
import mailicon from "../../images/mailicon.png"
import gpsicon from "../../images/gpsicon.png"
import local from "../../images/Arizaga-local.jpg"
const Footer = () => {
  const año = new Date().getFullYear()

  return (
    <>
      <div className='arizaga__footer-contacto' id="footer">
        <h1>Contacto</h1>
      </div>
    <div className='arizaga__footer'>
      <div className='arizaga__footer-info'>
        <div className='arizaga__footer-info_content'>
          <h2>INFORMACIÓN</h2>
          <div>
            <img className='arizaga__footer-phone_img' src={gpsicon} alt="phone-icon" />
            <p>San Martin 3020 - Villa Constitución</p>
          </div>
          <div>
            <img className='arizaga__footer-mail_img' src={phoneicon} alt="mail-icon" />
              <div>
                <div className='arizaga__footer-mail'>
                  <p>03400-470863/735</p>
                  <p>03400-477953</p>
                  <p>+54 9 336 436 2433</p>
                </div>
              </div>
          </div>
          <div>
            <img className='arizaga__footer-gps_img' src={mailicon} alt="gps-icon" />
            <p>arifransrl@hotmail.com</p>
          </div>
        </div>

        <div className='arizaga__footer-consulta'>
          <h2>CONSULTA RÁPIDA</h2>
          <form action="mailto:arifransrl@hotmail.com" method="POST">
            <input type="text" name="nombre"  placeholder='NOMBRE'/>
            <input type="email" name="email"  placeholder='EMAIL'/>
            <input type="text" name="telefono" placeholder='TELEFONO'/>
            <textarea type="text" name="mensaje" placeholder='MENSAJE' />
            <button type="submit">ENVIAR</button>
          </form>
        </div>

        <div className='arizaga__footer-ubicacion'>
            <h2>NUESTRA OFICINA</h2>
            <img src={local} alt="edificio"/>
        </div>

      </div>
    </div>
    <div className='arizaga__footer-derechosreservados'>
        <p>Derechos reservados @ ARIFRAN S.R.L {año} | Desarrollado por <a className="arizaga__footer-zanfor" href='https://zanfor-media.com/'>ZanFor-Media</a></p>
    </div>
  </>
  )
}

export default Footer