import React from 'react';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import { Navbar } from "./components";
import { ErrorPage, Productos } from './containers';
import Home from "./Home"
import "./App.css"


const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />}/>
          <Route path="/productos" element={<Productos />}/>
          <Route path="*" element={<ErrorPage />}/>
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App