import React from 'react';
import "./productos.css";
import { data } from "./data"
import { Footer } from "../../containers"

const Productos = () => {
  return (
    <div>
      <hr />
        <h1 className='arizaga__productos-title fade-in-top'>Familia de productos</h1>
        <div className='arizaga__productos fade-in-top'>
          {data.map((title,index) => {
            return (
              <div className='arizaga__productos-container' id={title.title + index}>
                <h3 className='arizaga__productos-box-text'>{title.title}</h3>
              </div>)
            })}
        </div>
        <div className='arizaga__productos-container2'>
          <h2 className='arizaga__productos-text'>Si no encontras el producto que estás buscando no dudes en consultar a nuestro equipo
                y nosotros nos encargamos de conseguirlo.
            </h2>
            <svg width="16" height="65" viewBox="0 0 16 65" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.29291 64.2071C7.68344 64.5976 8.3166 64.5976 8.70713 64.2071L15.0711 57.8431C15.4616 57.4526 15.4616 56.8195 15.0711 56.4289C14.6806 56.0384 14.0474 56.0384 13.6569 56.4289L8.00002 62.0858L2.34316 56.4289C1.95264 56.0384 1.31947 56.0384 0.928949 56.4289C0.538425 56.8195 0.538425 57.4526 0.928949 57.8432L7.29291 64.2071ZM7 0.5L7.00002 63.5L9.00002 63.5L9 0.5L7 0.5Z" fill="black"/>
            </svg>
        </div>
        <Footer />
    </div>
  )
}

export default Productos