import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import "./navbar.css";
import { Link } from 'react-router-dom';


const Navbar = () => {


  const [toggleMenu, setToggleMenu ] = useState(false);
  let location = useLocation();
  const [path, setPath] = useState(location)

  useEffect(() => {
    console.log(location)
    setPath(location)
  }, [location])

  let navbarChange;
  if (path.pathname === "/") {
    navbarChange =
    (<>
      <p className='navbar-links'><a href="#offer">Productos</a></p>
      <p className='navbar-links'><a href="#arizaga">Servicio</a></p>
    </>
    );
  } else if (path.pathname === "/productos") {
    navbarChange = null;
  }

  const Menu = () => (
    <>
            <p className='navbar-links'><Link to="/">Inicio</Link></p>
            {navbarChange}
            <p className='navbar-links'><a href="#footer">Contacto</a></p>
    </>
  )

  return (
    <div className='arizaga__navbar'>
      <div className='arizaga__navbar-links'>
        <div className='arizaga__navbar-logo'>
          <Link to="/"><h2>ARIFRAN S.R.L</h2></Link>
        </div>
        <div className='arizaga__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='arizaga__navbar-menu'>
        {toggleMenu 
        ? <RiCloseLine color="#000" size={27} onClick={() => setToggleMenu(false)}/>
        : <RiMenu3Line color="#000" size={27} onClick={() => setToggleMenu(true)}/>
        }
        {toggleMenu && (
          <div className='arizaga__navbar-menu_container scale-up-center'>
            <div className='arizaga__navbar-menu_container-links'>
            <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar