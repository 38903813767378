import React from 'react';
import "./header.css";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className='arizaga__header' id="#home">
      <div className='arizaga__header-content fade-in-left'>
        <h5>Proveedor de productos industriales</h5>
        <h1>Optimice sus operaciones con nuestro servicio de suministros industriales de alta  calidad</h1>
        <h3>Desde materias primas hasta equipos de seguridad, tenemos todo lo que necesita para mejorar la eficiencia y la productividad</h3>
        <Link to="/productos"><button type="button" className='arizaga__header-btn'>VER PRODUCTOS</button></Link>
      </div>
    </div>
  )
}

export default Header