import React from 'react';
import "./article.css";

const Article = ( { imgUrl, title } ) => {
  return (
    <div className='arizaga__article'>
        <div className='arizaga__article-img'>
          <img src={imgUrl} alt="product"/>
          <div className='arizaga__article-title_container'>
            <h3 className='arizaga__article-title'>{title}</h3>
          </div>
        </div>
    </div>
  )
}

export default Article