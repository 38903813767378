import React from 'react';
import "./arizaga.css"
import logo from "../../images/arizaga-logo.png"

const Arizaga = () => {
  return (
    <div className='arizaga__servicio' id="arizaga">
      <div className='arizaga__servicio-container'>
        <div className='arizaga__servicio-text'>
         <h1>El servicio de ARIFRAN S.R.L</h1>
          <p>Somos un proveedor industrial lider, ofreciendo una amplia gama de productos y servicios para satisfacer las necesidades de las empresas en diversas industrias.</p>
          <p>Nuestro extenso inventario incluye todo, desde materias primas y maquinaria hasta equipos y herramientas de seguridad. Solo obtenemos nuestros productos de fabricantes acreditados, asegurándonos de que cumplan con los más altos estándares de calidad y rendimiento.</p>
          <p>Nos enorgullecemos de nuestro excelente servicio al cliente, y estamos dedicados a garantizar que su experiencia con nosotros sea lo más fluida y eficiente posible.</p>
          <p>Si estás buscando un proveedor confiable no busque más allá de nuestra empresa, contactanos para obtener más información sobre como podemos ayudar al éxito de su negocio.</p>
        </div>
        <div className='arizaga__servicio-icon'>
          <img src={logo} alt="Arizaga logo"/>
        </div>
      </div>

    </div>
  )
}

export default Arizaga