import React from 'react'
import "./ErrorPage.css"
import logo from "../../images/arizaga-logo.png"
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className='arizaga__error'>
        <div className='arizaga__error-container'>   
            <div className='arizaga__error-text'>
                <h1>Ups! Hay un error en el sitio, estamos trabajando para resolverlo.</h1>
                <h1>Volve al inicio haciendo click en nuestro logo.</h1>
            </div>
            <div className='arizaga__error-img'>
                <Link to="/"><img classname="arizaga__error-logo" src={logo} alt="arizaga logo" /></Link>
            </div>
        </div>
    </div>
  )
}

export default ErrorPage