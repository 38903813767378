import React from 'react';
import "./offer.css";
import Article from "../../components/article/Article"
import img01 from "../../images/img01.jpg"
import img02 from "../../images/img02.jpg"
import img03 from "../../images/img03.jpg"
import img04 from "../../images/img04.jpg"
import img05 from "../../images/img05.jpg"
import { Link } from 'react-router-dom';



const Offer = () => {
  return (
    <div className='arizaga__offer' id="offer">
      <div className='arizaga__offer-heading'>
        <h1>Que ofrecemos?</h1>
      </div>
      <div className='arizaga__offer-container'>
        <div className='arizaga__offer-container-groupA'>
          <Article imgUrl={img01} title="Filtros"/>
          <Article imgUrl={img02} title="Seguridad Industrial"/>
        </div>
        <div className='arizaga__offer-container-groupB'>
          <Article imgUrl={img03} title="Válvulas"/>
          <Article imgUrl={img04} title="Equipamiento para laboratorio"/>
          <Article imgUrl={img05} title="Resortes"/>
        </div>
        <div className='arizaga__offer-subtitle'>
        <Link to="/productos"><h2>Ver los demás productos</h2></Link>
            <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.3889 4.79993C16.5828 4.60334 16.5807 4.28677 16.3841 4.09284L13.1805 0.932596C12.9839 0.738668 12.6674 0.740824 12.4734 0.937411C12.2795 1.134 12.2817 1.45057 12.4782 1.6445L15.3259 4.4536L12.5168 7.30122C12.3228 7.49781 12.325 7.81439 12.5216 8.00831C12.7182 8.20224 13.0347 8.20009 13.2287 8.0035L16.3889 4.79993ZM1.0034 5.05114L16.0364 4.94878L16.0295 3.9488L0.996596 4.05116L1.0034 5.05114Z" fill="black"/>
            </svg>
        </div>
      </div>
    </div>
  )
}

export default Offer