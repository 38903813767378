import React from 'react';
import "./companies.css"
import Tenaris from "../../images/Tenaris.png"
import Acindar from "../../images/Acindar.png"
import LaminadosInds from "../../images/LaminadosInds.png"
import LouisDreyfus from "../../images/LouisDreyfus.png"
import TAMSA from "../../images/TAMSA.png"
import Tecpetrol from "../../images/Tecpetrol.png"
import Ternium from "../../images/Ternium.png"

const Companies = () => {
  return (
    <div className='arizaga__companies'>
      <div className='arizaga__companies-itemA'>
        <img src={Tenaris} alt="Cliente" />
        <img src={Acindar} alt="Cliente" />
        <img src={LaminadosInds} alt="Cliente" />
      </div>
      <div className='arizaga__companies-itemB'>
        <img src={TAMSA} alt="Cliente" />
        <img src={Tecpetrol} alt="Cliente" />
        <img src={Ternium} alt="Cliente" />
      </div>
    </div>
  )
}

export default Companies